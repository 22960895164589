.card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 2px 1px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-radius: 8px;
}
.media:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 2px 1px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-radius: 8px;
  cursor: pointer;
}

.article-header {
  color: $secondary;
  border-radius: 8px;
  .article-header-icon {
      margin-right: 10px;
    }
}

.form-control:read-only {
  background-color: #e9ecef;
  opacity: 1;
}

.table-header-icon-column {
  width: 5%;
}

.table-header-row {
  background-color: #eaeaea !important;
}

.custom-background-light-green {
  background-color: #6cd43f !important;
}

.custom-background-green {
  background-color: #37791b !important;
}

.custom-background-orange {
  background-color: #e76400 !important;
}

.custom-background-red {
  background-color: #c42f2f !important;
}

.custom-background-grey {
  background-color: #b2b2b2 !important;
}

.icon-pointer {
  cursor: pointer;
  &:hover {
    color: $primary;
    transition: .3s;
  }
}