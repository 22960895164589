.user-nav {
  background: var(--bs-btn-bg);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
  color: white;
  justify-content: center;
  display: flex;
  & > a {
    font-size: 1rem;
    color: white;
    padding: 0;
    &:hover {
      color: white;
    }
    &::after {
      content: none;
    }
  }
}
.nav_index {
  position: relative;
  z-index: 2;
}

.motion-index {
  z-index: 1;
  position: relative;
}
@media only screen and (min-width: 600px) {
  .nav_index {
    height: 50px;
  }
}
.form-control:focus {
  box-shadow: 0 0.25rem #c3001f25;
}
.breadcrumb-item {
  & a {
    text-decoration: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}