.media:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 2px 1px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-radius: 8px;
  cursor: pointer;
}
.article-header {
  color: $secondary;
  // background-color: #fbfbfb;
  border-radius: 8px;
}
